<template>
  <!-- 产品&服务 -->
  <div>
    <Newben></Newben>
    <div class="assets-img">
      <img src="https://kailuo-cms.oss-cn-shenzhen.aliyuncs.com/CMS_base/gwimgs/产品1.webp" />
    </div>
    <!-- Tab导航 -->
    <TabSwitch :categories="categories" :currentCategoryIndex="currentCategoryIndex"
      @categorySelected="handleCategorySelected"></TabSwitch>
    <!-- 轮播图 -->
    <Carousel :categories="categories" :currentCategoryIndex="currentCategoryIndex"
      @categoryChange="handleCategoryChange"></Carousel>
    <!-- 配送服务 -->
    <NodFne></NodFne>
    <!-- 成功案例 -->
    <Fast>成功案例</Fast>
    <BottomTonCopy class="listBox"></BottomTonCopy>
  </div>
</template>

<script>
import Newben from '@/components/Newben';
import Carousel from '@/components/Carousel';
import NodFne from '@/components/NodFne';
import Fast from '@/components/Fast';
import BottomTonCopy from '@/components/BottomTonCopy';
import TabSwitch from '@/components/TabSwitch.vue';
import { getProductList } from '@/api';

export default {
  name: 'ParentComponent',
  components: {
    Newben,
    Carousel,
    NodFne,
    Fast,
    BottomTonCopy,
    TabSwitch,
  },
  data() {
    return {
      categories: [], // 动态获取的分类数据
      currentCategoryIndex: 0,
    };
  },
  methods: {
    handleCategorySelected(index) {
      this.currentCategoryIndex = index;
    },
    handleCategoryChange(index) {
      this.currentCategoryIndex = index;
    },
    async fetchCategories() {
      try {
        const response = await getProductList();
        this.categories = response.data.map((category) => ({
          name: category.name,
          scode: category.scode,
          icon: category.ico,
          products: category.product,
        }));
      } catch (error) {
        console.error("获取分类列表失败:", error);
      }
    },
  },
  async created() {
    await this.fetchCategories();
  },
};
</script>

<style scoped lang="less">
.listBox {
  width: 1920px;
  background-color: #f0f0f0;
  border-top: 4px solid #f67100;
  position: relative;
}

.assets-img {
  width: 1920px;
  height: 596px;

  img {
    width: 1920px;
    height: 596px;
  }
}
</style>
