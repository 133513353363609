<template>
  <div>
    <div class="baby-Surface">
      <ul class="baby-Surface-ul">
        <li
          v-for="(category, index) in categories"
          :key="category.scode"
          @click="selectCategory(index)"
          @mouseover="onMouseOver(index)"
          @mouseleave="onMouseLeave(index)"
          :class="{ active: index === currentCategoryIndex }"
        >
          <a>
            <i class="iconfont">
              <img
                :class="getIconClass(index)"
                :src="
                  category.hover
                    ? getIconUrl(category.pic)
                    : getIconUrl(category.icon)
                "
                alt=""
              />
            </i>
            {{ category.name }}
          </a>
          <span v-if="index === currentCategoryIndex"></span>
        </li>
      </ul>
    </div>
  </div>
</template>


<script>
import { getProductList } from "@/api"; // 导入获取分类列表的 API 函数

export default {
  props: ["currentCategoryIndex"], // 接收父组件传递的当前分类索引
  data() {
    return {
      categories: [], // 分类数据
    };
  },
  methods: {
    async fetchCategories() {
      try {
        const response = await getProductList();
        this.categories = response.data.map((category) => ({
          name: category.name,
          scode: category.scode,
          icon: category.ico,
          pic: category.pic,
          products: category.product,
          hover: false, // 初始化 hover 状态
        }));
        this.$emit("categoriesFetched", this.categories); // 通知父组件分类数据已获取
      } catch (error) {
        console.error("获取分类列表失败:", error);
      }
    },
    selectCategory(index) {
      this.$emit("categorySelected", index); // 传递选中的分类索引给父组件
    },
    getIconUrl(path) {
      return this.isCompleteUrl(path)
        ? path
        : `${process.env.VUE_APP_BASE_URL}${path}`;
    },
    isCompleteUrl(url) {
      return (
        typeof url === "string" &&
        (url.startsWith("http://") || url.startsWith("https://"))
      );
    },
    getIconClass(index) {
      // 根据索引返回相应的类名，例如：'xtb-a', 'xtb-b', 等
      const classes = [
        "xtb-a",
        "xtb-d",
        "xtb-b",
        "xtb-c",
        "xtb-e",
        "xtb-f",
        "xtb-g",
        "xtb-h",
      ];
      return classes[index % classes.length]; // 防止索引越界
    },
    onMouseOver(index) {
      this.categories[index].hover = true;
    },
    onMouseLeave(index) {
      this.categories[index].hover = false;
    },
  },
  created() {
    this.fetchCategories();
  },
};
</script>


<style lang="less" scoped>
.baby-Surface {
  width: 1920px;
  height: 90px;
  line-height: 90px;

  .baby-Surface-ul {
    width: 1056px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    color: #e6e6e6;

    li {
      width: 175px;
      height: 90px;
      position: relative;
      cursor: pointer;

      a {
        font-family: PingFang SC;
        display: block;
        font-weight: 500;
        height: 90px;
        font-size: 16px;
        line-height: 90px;
        text-align: center;
        color: #666666;

        .iconfont {
          vertical-align: middle;
          margin-right: 9px;
          width: 32px;
          height: 32px;
          color: #666666;
        }
      }

      span {
        width: 0.5px;
        height: 24px;
        top: 35px;
        right: 0;
        position: absolute;
        background: #e6e6e6;
      }
    }

    li:hover a {
      color: #f67100;
    }

    li:hover .iconfont {
      color: #f67100;
    }
  }

  .xtb-a {
    width: 22px;
    height: 22px;
    position: relative;
    top: 5px;
  }

  .xtb-b {
    width: 22px;
    height: 22px;
    position: relative;
    top: 3px;
  }

  .xtb-c {
    width: 22px;
    height: 22px;
    position: relative;
    top: 3px;
  }

  .xtb-d {
    width: 22px;
    height: 22px;
    position: relative;
    top: 3px;
  }

  .xtb-e {
    width: 22px;
    height: 22px;
    position: relative;
    top: 3px;
  }

  .xtb-f {
    width: 22px;
    height: 22px;
    position: relative;
    top: 3px;
  }

  .xtb-g {
    width: 22px;
    height: 22px;
    position: relative;
    top: 3px;
  }

  .xtb-h {
    width: 22px;
    height: 22px;
    position: relative;
    top: 3px;
  }

  .baby-Surface-ul .active {
    border-bottom: 4px solid #f67100;
  }

  .lia {
    cursor: pointer;
  }
}
</style>

