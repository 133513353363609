<template>
  <div>
    <div class="Fast">
      <img src="https://kailuo-cms.oss-cn-shenzhen.aliyuncs.com/CMS_base/gwimgs/lai06.webp" alt="" />
      <div class="pinpai">
        成功案例
        <span></span>
      </div>
      <div class="Fast-klen-btn">
        <div class="Fast-klen1"><img src="https://kailuo-cms.oss-cn-shenzhen.aliyuncs.com/CMS_base/gwimgs/lai07.webp" /></div>
        <div class="Fast-klen2">
          <div class="pinpai-poi">
            配套服务
            <span></span>
          </div>
          <p>
            凯螺到家：主要经营为 互联网+基建
            的商业模式。在线上平台业务中，向用户提供一站式基础建材解决方案，包括信息发现及与区域服务供应商智能匹配、实现客户在线上下单，线下就会有相应的配送与搬运体系的配合，以及客户意见反馈和其他增值服务；
            同时，凯螺到家向平台服务每位供应商提供订单推荐、会员服务、推介服务及保险推广及供应链管理解决方案。在基础建材业务中，为客户提供施工基础建材、设备租赁买卖、打拆清运
            等等服务及基础主要高性价比的材料。
          </p>
        </div>
        <span class="Fast-klen-span01"
          ><img src="../assets/a2-xiayiye.png" alt=""
        /></span>
        <span class="Fast-klen-span02"
          ><img src="../assets/a-xiayiye 拷贝.png" alt=""
        /></span>
      </div>
      <div class="Fast-klen">
        <div class="Fast-klen1"><img src="https://kailuo-cms.oss-cn-shenzhen.aliyuncs.com/CMS_base/gwimgs/a-LOGO.webp" /></div>
        <div class="Fast-klen2">
          <div class="pinpai-poi">
            品牌合作
            <span></span>
          </div>
          <p>
            凯螺到家：主要经营为 互联网+基建
            的商业模式。在线上平台业务中，向用户提供一站式基础建材解决方案，包括信息发现及与区域服务供应商智能匹配、实现客户在线上下单，线下就会有相应的配送与搬运体系的配合，以及客户意见反馈和其他增值服务；
            同时，凯螺到家向平台服务每位供应商提供订单推荐、会员服务、推介服务及保险推广及供应链管理解决方案在基础建材业务中，为客户提供施工基础建材、设备租赁买卖、打拆清运
            等等服务及基础主要高性价比的材料。
          </p>
        </div>
        <span class="Fast-klen-span01"
          ><img src="../assets/a2-xiayiye.png" alt=""
        /></span>
        <span class="Fast-klen-span02"
          ><img src="../assets/a-xiayiye 拷贝.png" alt=""
        /></span>
      </div>
    </div>
    <!-- <Carousel>成功案例</Carousel> -->
  </div>
</template>

<script>
export default {
  name: 'FastNmae'
}
</script>

<style scoped lang="less">
.Fast {
  .Fast-klen {
    .Fast-klen-span01 {
      position: absolute;
      width: 50px;
      line-height: 50%;
      z-index: 9;
      top: 180px;
      right: 35px;
      img {
        width: 50px;
        height: 50px;
      }
    }
    .Fast-klen-span02 {
      position: absolute;
      width: 50px;
      line-height: 50%;
      z-index: 9;
      top: 180px;
      left: 35px;
      img {
        width: 50px;
        height: 50px;
      }
    }
    .Fast-klen1 {
      width: 573px;
      height: 345px;
      img {
        width: 573px;
        height: 345px;
      }
    }
    .Fast-klen2 {
      flex: 1;
      .pinpai-poi {
        width: 160px;
        height: 53px;
        margin: 0 auto;
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 40px;
        color: #333333;
        span {
          width: 110px;
          height: 3px;
          background: #f67100;
          display: block;
          margin-top: 15px;
          margin: 12px auto;
        }
      }
      p {
        width: 706px;
        height: 166px;
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #333333;
        line-height: 30px;
        margin: 60px 0 0 87px;
      }
    }
    display: flex;
    padding: 61px 120px;
    margin-left: 159px;
    top: 220px;
    position: absolute;
    width: 1602px;
    height: 468px;
    background: rgba(255, 255, 255, 0.5);

  }
  position: relative;
  width: 1920px;
  height: 1263.5px;
  background: #ffffff;
  img {
    width: 1920px;
    height: 1263.5px;
  }
  .Fast-klen-btn {
    .Fast-klen-span01 {
      position: absolute;
      width: 50px;
      line-height: 50%;
      z-index: 9;
      top: 180px;
      right: 35px;
      img {
        width: 50px;
        height: 50px;
      }
    }
    .Fast-klen-span02 {
      position: absolute;
      width: 50px;
      line-height: 50%;
      z-index: 9;
      top: 180px;
      left: 35px;
      img {
        width: 50px;
        height: 50px;
      }
    }
    .Fast-klen1 {
      width: 573px;
      height: 345px;
      // background-color: salmon;
      img {
        width: 573px;
        height: 345px;
      }
    }
    .Fast-klen2 {
      flex: 1;
      .pinpai-poi {
        width: 160px;
        height: 53px;
        margin: 0 auto;
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 40px;
        color: #333333;
        span {
          width: 110px;
          height: 3px;
          background: #f67100;
          display: block;
          margin-top: 15px;
          margin: 12px auto;
        }
      }
      p {
        width: 706px;
        height: 166px;
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #333333;
        line-height: 30px;
        margin: 60px 0 0 87px;
      }
    }
    display: flex;
    padding: 61px 120px;
    margin-left: 159px;
    bottom: 80px;
    position: absolute;
    width: 1602px;
    height: 468px;
    // background: #ffffff;
    // opacity: 0.5;
    background: rgba(255, 255, 255, 0.5);
  }
}
.pinpai {
  position: absolute;
  top: 0px;
  width: 1920px;
  height: 154px;
  font-family: PingFang SC;
  z-index: 9;
  font-weight: 500;
  font-size: 60px;
  color: #333333;
  line-height: 110px;
  text-align: center;
  margin: 77px auto 19px;
  span {
    width: 110px;
    height: 3px;
    background: #f67100;
    display: block;
    text-align: center;
    // text-align: center;
    margin: 0 auto;
  }
}
.Fast img[data-v-0fd2f68c] {
    width: 100vw;
    height: 65.80729vw;
    padding: 0;
    margin: 0;
    position: relative;
    bottom: -20px;
}
</style>
