<template>
  <div>
    <!-- 导航栏 -->
    <Medse></Medse>
    <!-- 头部背景图 -->
    <div class="top-imgs">
      <img src="https://kailuo-cms.oss-cn-shenzhen.aliyuncs.com/CMS_base/gwimgs/小程序1.webp" alt="default" />
    </div>
    <!-- Tab导航 -->
    <TabSwitch :currentCategoryIndex="currentCategoryIndex" @categorySelected="handleCategorySelected"
      @categoriesFetched="handleCategoriesFetched"></TabSwitch>
    <!-- 产品轮播图 -->
    <Carousel :categories="categories" :currentCategoryIndex="currentCategoryIndex"
      @categoryChange="handleCategoryChange"></Carousel>
    <!-- 底部公司详情 -->
    <BottomTonCopy class="listBox"></BottomTonCopy>
  </div>
</template>

<script>
import Medse from "@/components/Medse";
import BottomTonCopy from "@/components/BottomTonCopy";
import Carousel from "@/components/Carousel";
import TabSwitch from "@/components/TabSwitch.vue";

export default {
  name: "placeName",
  components: {
    BottomTonCopy,
    Medse,
    Carousel,
    TabSwitch,
  },
  data() {
    return {
      categories: [], // 动态获取的分类数据
      currentCategoryIndex: 0,
    };
  },
  methods: {
    handleCategorySelected(index) {
      this.currentCategoryIndex = index;
    },
    handleCategoryChange(index) {
      this.currentCategoryIndex = index;
    },
    handleCategoriesFetched(categories) {
      this.categories = categories;
    },
  },
};
</script>

<style scoped lang="less">
.listBox {
  width: 1920px;
  background-color: #f0f0f0;
  border-top: 4px solid #f67100;
  position: relative;
}

.btn-Details {
  position: relative;
  margin: 0 auto;
  width: 1524px;
  height: 346px;

  ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    overflow: hidden;
    padding-inline-start: 0px;

    li {
      margin: 0 19px;

      .btn-Details-Biagram {
        width: 268px;
        height: 266px;
        display: flex;

        a {
          img {
            width: 268px;
            height: 266px;
          }
        }
      }

      .btn-Details-Explain {
        width: 268px;
        height: 80px;
        background-color: #ffffff;
        border-radius: 0px 0px 7px 7px;
        line-height: 80px;
        text-align: center;
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #777777;

        p {
          margin: 0;
        }
      }
    }
  }

  .gjiantou-left {
    position: absolute;
    width: 39px;
    z-index: 1;
    top: 45%;
    left: -70px;
    line-height: 2px;

    img {
      width: 38px;
      height: 38px;
      bottom: 0;
    }
  }

  .gjiantou-right {
    position: absolute;
    width: 39px;
    z-index: 1;
    top: 45%;
    right: -70px;
    line-height: 2px;

    img {
      width: 38px;
      height: 38px;
    }
  }
}

.bottom-DisplayFrame {
  width: 1920px;
  height: 590px;
  background-color: #f0f0f0;
  margin: 0 auto;

  .pinpai {
    padding-top: 30px;
    margin-bottom: 40px;
    // display: block;
    width: 1920px;
    height: 154px;
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 60px;
    color: #333333;
    line-height: 110px;
    text-align: center;

    span {
      width: 110px;
      height: 3px;
      background: #f67100;
      display: block;
      margin: 0 auto;
    }
  }
}

.top-imgs {
  width: 1920px;
  height: 1078px;

  img {
    width: 100%;
    height: 100%;
  }
}

/* 将 ol, ul, li 列表的列表标记设置为 none */
ol,
ul {
  list-style: none;
}

/* 去除链接的下划线，并设置为默认的颜色 */
a {
  text-decoration: none;
  color: inherit;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: inherit;
  /* 继承父元素的文本颜色 */
  text-decoration: none;
  /* 移除下划线 */
}
</style>
