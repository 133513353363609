<template>
  <!-- 点击返回顶部 -->
  <div class="hello_world">
    <div v-if="showElement" class="top">
      <img
        @click="scrollToTop"
        src="../assets/xiangshangjiantou@2x.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopupName',
  data () {
    return {
      showElement: false,
      name: 100
    }
  },
  methods: {
    // 点击返回顶部
    scrollToTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    initScroll () {
      // 滚动条的位置
      const height = document.documentElement.scrollTop
      // console.log(height)
      if (height >= this.name) {
        this.showElement = true
      } else {
        this.showElement = false
      }
    }
  },
  mounted () {
    // 监听滚动事件
    window.addEventListener('scroll', this.initScroll)
  },
  beforeDestroy () {
    // 移除注册事件
    window.removeEventListener('scroll', this.initScroll)
  }
}
</script>
<style scoped lang="less">
.hello_world {
  position: relative;
  text-align: center;
  line-height: 67px;
}
// 按钮 点击返回顶部
.top {
  position: fixed;
  width: 34px;
  height: 34px;
  bottom: 150px;
  right: 50px;
  border-radius: 50%;
  display: block;
  z-index: 99999;
  img {
    width: 69px;
    height: 69px;
    top: -9px;
    left: -9px;
    position: absolute;
  }
  &:hover{
    cursor: pointer;
  }
}
</style>
