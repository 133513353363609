<template>
  <!-- 行业资讯 -->
  <div>
    <!-- 导航栏 -->
    <Medse></Medse>
    <div class="Industry">
      <img
        src="https://kailuo-cms.oss-cn-shenzhen.aliyuncs.com/CMS_base/gwimgs/行业资讯1.webp"
        alt=""
      />
    </div>
    <!-- 中部分类导航 -->
    <div class="baby-Surface">
      <ul class="baby-Surface-ul">
        <li
          v-for="category in categories"
          :key="category.scode"
          :class="{ 'xzzt-1': selectedCategory === category.scode }"
          @click="selectCategory(category.scode)"
        >
          <a href="#">
            <i :class="category.iconClass">&#xe620;</i>{{ category.name }}
          </a>
          <span></span>
        </li>
      </ul>
    </div>
    <!-- 资讯-新闻-列表 -->
    <ul class="ahiSao-ul">
      <li v-for="news in filteredNews" :key="news.id" class="akcaade">
        <router-link
          :to="{ path: '/industryDetails', query: { id: news.id } }"
          tag="li"
          class="akcaade"
        >
          <div class="akcaade-img">
            <!-- 拼接图片路径 -->
            <img :src="getImageUrl(news.ico)" alt="新闻图片" />
            <div class="box-name">
              <span class="title-1">{{ news.title }}</span>
              <p>{{ news.description }}</p>
            </div>
            <div class="neger">
              <div class="hned">{{ formatDay(news.update_time) }}</div>
              <p>{{ formatYearMonth(news.update_time) }}</p>
            </div>
          </div>
        </router-link>
      </li>
    </ul>
    <FenY></FenY>
    <BottomToncopy class="listBox"></BottomToncopy>
  </div>
</template>

<script>
import { getNewsList } from "@/api";
import { imageService } from "@/utils/request";
import FenY from "@/components/FenY";
import BottomToncopy from "@/components/BottomTonCopy.vue";
import Medse from "@/components/Medse.vue";

export default {
  name: "industryName",
  data() {
    return {
      products: { data: [] },
      selectedCategory: "13",
      categories: [
        { scode: "13", name: "全部新闻", iconClass: "iconfont &#xe620;" },
        { scode: "14", name: "行业动态", iconClass: "iconfont &#xe618;" },
        { scode: "15", name: "媒体报道", iconClass: "iconfont &#xe617;" },
      ],
    };
  },
  created() {
    this.fetchNewsList();
  },
  computed: {
    filteredNews() {
      const category = this.products.data.find(
        (category) => category.scode === this.selectedCategory
      );
      return category ? category.product : [];
    },
  },
  methods: {
    async fetchNewsList() {
      try {
        const response = await getNewsList();
        this.products = response;
        // console.log("获取-新闻列表:", response);
      } catch (error) {
        console.error("获取-新闻列表失败:", error);
      }
    },
    selectCategory(scode) {
      this.selectedCategory = scode;
    },
    isCompleteUrl(url) {
      return url.startsWith("http://") || url.startsWith("https://");
    },
    getImageUrl(path) {
      return this.isCompleteUrl(path)
        ? path
        : `${imageService.defaults.baseURL}${path}`;
    },
    // 日期格式化
    formatYearMonth(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      return `${year}.${month}`;
    },
    formatDay(dateString) {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0");
      return day;
    },
  },
  components: {
    FenY,
    BottomToncopy,
    Medse,
  },
};
</script>

<style scoped lang="less">
.listBox {
  width: 1920px;
  background-color: #f0f0f0;
  border-top: 4px solid #f67100;
  position: relative;
}

.ahiSao-ul {
  min-height: 1200px;
  width: 1320px;
  margin: 0 auto;
  padding: 0 50px;
  padding-top: 1px;
  background-color: #ffffff;

  .akcaade {
    // width: 1220px;
    width: 1270px;
    height: 250px;
    margin-top: 50px;
    border-bottom: 0.5px solid #f1f1f1;
    background-color: #ffffff;

    .akcaade-img {
      cursor: pointer;
      display: flex;
      width: 342px;
      height: 200px;

      .box-name {
        padding-left: 80px;
        background-color: #ffffff;

        .title-1 {
          margin-top: 50px;
          width: 430px;
          // height: 26px;
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 28px;
          color: #333333;
        }

        p {
          margin-top: 47px;
          width: 624px;
          height: 83px;
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #666666;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .neger {
        width: 6.9375vw;
        height: 114px;
        border-radius: 2px;
        margin-left: 33px;
        border: 0.5px solid #e6e6e6;
        // padding: 5px;
        background-color: #ffffff;

        .hned {
          width: 114px;
          height: 2.51042vw;
          background-color: #ffffff;
          text-align: center;
          font-family: PingFang SC;
          font-weight: bold;
          font-size: 38px;
          color: #6f6f6f;
        }

        p {
          width: 77px;
          height: 16px;
          display: block;
          margin: 0 auto;
          margin-top: 10px;
          font-family: PingFang SC;
          font-weight: bold;
          font-size: 20px;
          color: #6f6f6f;
        }
      }

      img {
        width: 342px;
        height: 200px;
      }
    }
  }
}
.li-box {
  width: 1270px;
  background-color: #d61f1f;
}

.baby-Surface {
  margin-bottom: 50px;
  width: 1920px;
  height: 90px;
  line-height: 90px;
  background-color: #fff;

  .baby-Surface-ul {
    width: 525px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    color: #e6e6e6;

    li {
      width: 175px;
      height: 90px;
      position: relative;
      line-height: 90px;
      text-align: center;

      a {
        font-family: PingFang SC;
        display: block;
        font-weight: 500;
        height: 40px;
        font-size: 16px;
        // line-height: 40px;
        text-align: center;
        color: #666666;

        .iconfont {
          vertical-align: middle;
          margin-right: 9px;
          width: 32px;
          height: 32px;
          color: #666666;
        }
      }

      span {
        width: 0.5px;
        height: 24px;
        right: 0;
        top: 32px;
        position: absolute;
        background: #e6e6e6;
      }
    }

    li:hover {
      border-bottom: 4px solid #f67100;
    }

    li:hover a,
    .iconfont {
      color: #f67100;
    }

    li:hover .iconfont {
      color: #f67100;
    }
  }

  // .baby-Surface-ul
}

.xzzt-1 {
  border-bottom: 4px solid #f67100;
}

.Industry {
  width: 1920px;
  height: 380px;
  background-color: #fff;

  img {
    width: 1920px;
    height: 380px;
  }
}

div {
  background-color: #f0f0f0;
}

/* 将 ol, ul, li 列表的列表标记设置为 none */
ol,
ul {
  list-style: none;
}

/* 去除链接的下划线，并设置为默认的颜色 */
a {
  text-decoration: none;
  color: inherit;
}
</style>
