// 接口管理
import { apiService, imageService } from '@/utils/request'

// 商品列表
export function getProductList() {
  return apiService({
    url: '/product/list',
    method: 'get',
  });
}

// 商品详情
export function getProductDetail(data) {
  return apiService({
    url: '/product/info',
    method: 'post',
    data,
  });
}

// 资讯新闻列表
export function getNewsList() {
  return apiService({
    url: '/industry/newslist',
    method: 'get',
  });
}

// 获取新闻详情
export function getNewsDetail(data) {
  return apiService({
    url: '/industry/newsinfo',
    method: 'post',
    data,
  });
}

// 合作招商
export function postPartner(data) {
  return apiService({
    url: '/join/form',
    method: 'post',
    data,
  });
}

// 合作招商验证码
export function getPartnerCaptcha() {
  return apiService({
    url: '/join/captcha',
    method: 'get',
  });
}

// 图片资源获取示例
export function getImageResource(path) {
  return imageService({
    url: path,
    method: 'get',
  });
}

// 导出所有的 API 函数
export default {
  getProductList,
  getProductDetail,
  getNewsList,
  getNewsDetail,
  postPartner,
  getPartnerCaptcha,
  getImageResource
};
