<template>
  <div>
    <!-- 分页器 -->
    <div class="paginationBox">
      <el-button size="mini" class="btn" @click="homePage">首页</el-button>
      <el-pagination
        class="el-pagination"
        background
        layout="prev, pager, next"
        prev-text="上一页"
        next-text="下一页"
        :current-page.sync="currentPage"
        :page-size="itemsPerPage"
        :page-count="computedPageCount"
        :total="totalItems"
        @current-change="handlePageChange"
      />
      <el-button size="mini" class="btn" @click="goToTrailerPage"
        >尾页</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'FenyName',
  components: {},
  data () {
    return {
      currentPage: 1,
      totalItems: 201,
      itemsPerPage: 20 // 假设每页显示20条数据
    }
  },
  computed: {
    computedPageCount () {
      return Math.ceil(this.totalItems / this.itemsPerPage)
    },
    trailerPage () {
      return this.computedPageCount
    }
  },
  methods: {
    handlePageChange (page) {
      this.currentPage = page
    },
    homePage () {
      this.currentPage = 1
    },
    goToTrailerPage () {
      this.currentPage = this.trailerPage
    }
  }
}
</script>
<style lang="less" scoped>
.paginationBox {
  display: flex;
  margin: 50px auto 80px;
  justify-content: space-between;
  width: 800px;
  height: 44px;
  .btn {
    margin: auto 10px;
    transition: all 0.3s;
    width: 74px;
    height: 44px;
    font-family: PingFang SC;
    border: 0;
    font-weight: 500;
    font-size: 16px;
    background-color: #ffffff;
    border-radius: 4px;
    color: #6f6f6f;
    text-align: center;
    &:hover {
      color: #fff;
      background-color: #ee7008;
    }
  }
  .el-pagination {
    width: 800px;
    height: 52px;
    color: #fff;
    flex: 1;
    line-height: 44px;
    padding: 0;
    ::v-deep {
      height: 44px;
      border-radius: 4px;
      button {
        width: 88px;
        height: 42px;
        transition: all 0.3s;
        color: #fff;
        background-color: #ee7008;
        &:hover {
          color: #fff;
          background-color: #ee7008;
        }
      }
      .el-pager {
        height: 42px;
        line-height: 42px;
        .active {
          background-color: #ee7008 !important;
        }
        .number {
          width: 44px;
          height: 44px;
          line-height: 44px;
          background: #ffffff;
          border-radius: 4px;
          margin: 0 10px;
        }
      }
    }
  }
}
// 重置默认样式
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// 文字溢出省略号
.text-ellipsis-2 {
  overflow: hidden;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
</style>
