<template>
    <div class="sform-box">
        <el-card class="box-card">
            <el-form :model="form" :rules="rules" ref="form" label-width="0">
                <div class="form-title">合作申请</div>
                <el-form-item prop="name">
                    <el-input v-model="form.name" placeholder="请输入您的姓名"></el-input>
                </el-form-item>
                <el-form-item prop="phone">
                    <el-input v-model="form.phone" placeholder="请输入您的电话"></el-input>
                </el-form-item>
                <el-form-item prop="captcha">
                    <el-row :gutter="10">
                        <el-col :span="14">
                            <el-input v-model="form.captcha" placeholder="请输入验证码"></el-input>
                        </el-col>
                        <el-col :span="10">
                            <div class="captcha">{{ captchaCode }}</div>
                        </el-col>
                    </el-row>
                </el-form-item>
                <!-- <el-form-item prop="request">
                    <el-input type="textarea" v-model="form.request" placeholder="请选择合作需求（可多选）"></el-input>
                </el-form-item> -->
                <!-- 下拉多选 -->
                <el-form-item prop="request">
                    <el-select v-model="value1" multiple placeholder="请选择">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit" class="submit-button">申请合作</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>

<script>
export default {
    name: "SuspendedForm",
    data() {
        return {
            options: [{
                value: '选项1',
                label: '黄金糕'
            }, {
                value: '选项2',
                label: '双皮奶'
            }, {
                value: '选项3',
                label: '蚵仔煎'
            }, {
                value: '选项4',
                label: '龙须面'
            }, {
                value: '选项5',
                label: '北京烤鸭'
            }],
            value1: [],
            form: {
                name: '',
                phone: '',
                captcha: '',
                request: ''
            },
            captchaCode: 'T2W8', // 这里是验证码的示例，可以根据需要动态生成
            rules: {
                name: [
                    { required: true, message: '请输入您的姓名', trigger: 'blur' }
                ],
                phone: [
                    { required: true, message: '请输入您的电话', trigger: 'blur' }
                ],
                captcha: [
                    { required: true, message: '请输入验证码', trigger: 'blur' }
                ]
            }
        };
    },
    methods: {
        onSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    alert('提交成功');
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    }
}
</script>

<style scoped>
.sform-box {
    position: fixed;
    top: 50%;
    right: 0px;
    transform: translate(-25%, -50%);
    z-index: 9999;
    width: 246px;
    height: 313px;
    background: #FFFFFF;
    border-radius: 4px;
    /* border: 1px solid #F67100; */
}

.box-card {
    width: 296px;
    height: 363px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #F67100;
}

.el-form-item {
    margin-bottom: 15px;
}

.el-input,
.el-textarea {
    height: 36px;
    line-height: 36px;
}

.captcha {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f5f5f5;
    height: 36px;
    text-align: center;
    line-height: 36px;
    font-size: 16px;
    color: #bfbfbf;
}

.submit-button {
    width: 100%;
    height: 36px;
    line-height: 36px;
    background-color: #ff6600;
    border-color: #ff6600;
}
</style>