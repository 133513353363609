import Vue from 'vue';
import Vuex from 'vuex';
import { getNewsList } from '@/api';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    newsList: [],
  },
  mutations: {
    setNewsList(state, newsList) {
      state.newsList = newsList;
    }
  },
  actions: {
    async fetchNewsList({ commit }) {
      try {
        const response = await getNewsList();
        commit('setNewsList', response.data);
      } catch (error) {
        console.error("获取-新闻列表失败:", error);
      }
    }
  }
});
