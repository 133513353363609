<template>
  <!-- 合作招商 -->
  <div>
    <Medse></Medse>
    <div class="RectanguLar">
      <img
        src="https://kailuo-cms.oss-cn-shenzhen.aliyuncs.com/CMS_base/gwimgs/合作1.webp"
        alt=""
      />
    </div>
    <!-- 表单 -->
    <div class="form-box">
      <div class="title-box">凯螺到家合作申请</div>
      <el-form class="form-box-1" ref="form" :model="form" :rules="rules">
        <el-form-item class="from-style" prop="contacts">
          <el-input
            class="input-sy"
            size="medium"
            clearable
            v-model="form.contacts"
            prefix-icon="el-icon-user"
            placeholder="请输入你的姓名"
          ></el-input>
        </el-form-item>
        <el-form-item class="from-style" prop="mobile">
          <el-input
            class="input-sy"
            size="medium"
            v-model="form.mobile"
            oninput="if(value.length>11)value=value.slice(0,11)"
            onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
            clearable
            prefix-icon="el-icon-mobile-phone"
            placeholder="请输入你的电话"
          ></el-input>
        </el-form-item>
        <el-form-item class="from-style" prop="captcha_code">
          <el-input
            size="medium"
            placeholder="请输入验证码"
            clearable
            prefix-icon="el-icon-circle-check"
            v-model="form.captcha_code"
          >
            <template slot="append">
              <el-image
                class="slot-size"
                :src="captchaImage"
                fit="contain"
                alt="验证码"
                @click="refreshCaptcha"
              />
            </template>
          </el-input>
        </el-form-item>

        <span class="kbj-box">
          <i class="el-icon-refresh-right kbj-a" @click="refreshCaptcha"></i>
          <span class="kbj-b" @click="refreshCaptcha"> 看不清，换一张 </span>
        </span>
        <el-form-item class="from-style" prop="content">
          <span class="check-text">请选择您的合作需求（可多选）</span>
          <el-checkbox-group v-model="form.content">
            <el-checkbox label="基础建材" name="content"></el-checkbox>
            <el-checkbox label="水管水线" name="content"></el-checkbox>
            <el-checkbox label="油漆涂料" name="content"></el-checkbox>
            <el-checkbox label="木工材料" name="content"></el-checkbox>
            <el-checkbox label="电气器材" name="content"></el-checkbox>
            <el-checkbox label="销售用品" name="content"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-button type="warning" class="custom-button" @click="onSubmit"
          >合作申请</el-button
        >
      </el-form>
    </div>
    <!-- 悬浮表单 -->
    <div class="td-box">
      <div class="btn-sbmt-2">
        <div class="btn-sbmt-ziT-2">行业痛点</div>
        <div class="btn-sbmt-p-2"></div>
        <!-- 具体痛点 -->
        <div class="jttd-box">
          <ul class="cards-container">
            <li class="card">
              <div class="card-content">
                <span class="number">01</span>
                <p class="tp1">建材行业深陷<br />应收账“泥潭”！</p>
                <p class="highlight">账期金额越来越大！</p>
              </div>
            </li>
            <li class="card">
              <div class="card-content">
                <span class="number">02</span>
                <p class="tp2">货物到场<br />服务再议价！</p>
                <p class="highlight-2">客户不满意！<br />复购无望！</p>
              </div>
            </li>
            <li class="card">
              <div class="card-content">
                <span class="number">03</span>
                <p class="tp3">货物乱放<br />乱摆！</p>
                <p class="highlight-3">客户难理解清！</p>
              </div>
            </li>
            <li class="card">
              <div class="card-content">
                <span class="number">04</span>
                <p class="tp4">恶意竞争下<br />只能等客户！</p>
                <p class="highlight-4">客户都让同行<br/>抢去了！</p>
              </div>
            </li>
            <li class="card">
              <div class="card-content">
                <span class="number">05</span>
                <p class="tp5">缺少品牌下<br/>企业无法持续运营</p>
                <p class="highlight-5">搬地址后，<br/>客户无法联系！</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="body-name">
        <img src="../../assets/imgs/bgt2x2.png" alt="" />
      </div>
    </div>
    <div class="btn-sbmt">
      <div class="btn-sbmt-ziT">申请流程</div>
      <div class="btn-sbmt-p"></div>
    </div>
    <div class="Lowni">
      <img
        src="https://kailuo-cms.oss-cn-shenzhen.aliyuncs.com/CMS_base/gwimgs/合作3.webp"
      />
    </div>
    <BottomTonCopy class="listBox"></BottomTonCopy>
  </div>
</template>

<script>
import Medse from "@/components/Medse.vue";
import BottomTonCopy from "@/components/BottomTonCopy";
import SuspendedForm from "@/components/SuspendedForm.vue";
import { getPartnerCaptcha, postPartner } from "@/api";

export default {
  name: "serviceName",
  data() {
    var checkPhone = (rule, value, callback) => {
      // 手机号验证
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else {
        const reg = /^1[3456789]\d{9}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    return {
      form: {
        contacts: "",
        mobile: "",
        captcha_code: "",
        content: ["基础建材"],
        captcha_key: "",
      },
      captchaImage: "",
      rules: {
        contacts: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          {
            min: 2,
            max: 10,
            message: "长度在 2 到 10 个字符",
            trigger: "blur",
          },
          {
            required: true,
            pattern: /^[\u4e00-\u9fa5_a-zA-Z0-9.·-]+$/,
            message: "姓名不支持特殊字符",
            trigger: "blur",
          },
        ],
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: checkPhone, trigger: "blur" },
        ],
        captcha_code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { min: 3, max: 5, message: "请输入正确的验证码", trigger: "blur" },
        ],
        // content: [{ required: true, message: '请选择合作需求', trigger: 'change' }]
      },
    };
  },
  components: {
    Medse,
    BottomTonCopy,
    SuspendedForm,
  },
  created() {
    this.refreshCaptcha(); // 初始化时获取验证码
  },
  methods: {
    async refreshCaptcha() {
      try {
        const response = await getPartnerCaptcha();
        // console.log(response, "111");
        this.captchaImage = response.result.img;
        this.form.captcha_key = response.result.key; // 保存 key
        // console.log(this.captchaImage, "验证码");
        // console.log(this.form.captcha_key, "KEY");
      } catch (error) {
        console.error("获取验证码失败", error);
      }
    },
    async onSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          try {
            const response = await postPartner(this.form);
            if (response.code === 200) {
              this.$message.success("提交成功");
            } else {
              this.$message.error(response.msg);
            }
          } catch (error) {
            this.$message.error("提交失败");
          }
        } else {
          console.log("表单验证失败");
          return false;
        }
      });
    },
  },
  computed: {},
};
</script>

<style scoped lang="less">
.listBox {
  width: 1920px;
  background-color: #f0f0f0;
  border-top: 4px solid #f67100;
  position: relative;
}

.el-input--prefix {
  margin-bottom: 15px;
}

.Lowni {
  margin: 60px auto 80px;
  margin-top: 58px;
  text-align: center;
  width: 1920px;
  height: 249px;

  img {
    width: 1603px;
    height: 249px;
  }
}

.btn-sbmt {
  width: 1920px;
  height: 160px;

  .btn-sbmt-ziT {
    width: 460px;
    height: 157px;
    text-align: center;
    margin: 0 auto;
    line-height: 157px;
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 60px;
    color: #333333;
  }

  .btn-sbmt-p {
    width: 110px;
    height: 3px;
    background: #f67100;
    text-align: center;
    margin: 0 auto;
  }
}
.td-box {
  position: relative;
}
.btn-sbmt-2 {
  width: 1920px;
  height: 160px;
  position: absolute;

  .btn-sbmt-ziT-2 {
    width: 460px;
    height: 157px;
    text-align: center;
    margin: 0 auto;
    line-height: 157px;
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 60px;
    color: #333333;
  }

  .btn-sbmt-p-2 {
    width: 110px;
    height: 3px;
    background: #f67100;
    text-align: center;
    margin: 0 auto;
  }
}

.body-name {
  width: 1920px;
  height: 685px;

  img {
    width: 1920px;
    height: 685px;
  }
}

// 表单
.form-box {
  top: 152px;
  right: 226px;
  position: absolute;
  width: 405px;
  height: 653px;
  // height: 580px;
  background-color: #ffffff;
  border-radius: 8px;

  .title-box {
    width: 405px;
    height: 115px;
    line-height: 115px;
    text-align: center;
    font-family: PingFang SC;
    font-weight: bold;
    font-size: 26px;
    color: #333333;
    background-color: #fff;
  }

  .form-box-1 {
    margin-left: 60px;
    width: 286px;
  }
}

.from-style {
  display: flex;
  width: 287px !important;
  height: 50px;
  z-index: 9999;

  .input-sy {
    width: 286px;
    // height: 52px;
  }
}

.custom-button {
  position: relative;
  margin-top: 120px;
  width: 284px;
  height: 50px;
  background-color: #f67100 !important;
  color: #fff !important;
  border-radius: 4px !important;
}

::v-deep.el-input--medium .el-input__inner {
  height: 52px;
  line-height: 52px;
}

.kbj-box {
  width: 286px;
  display: flex;
  justify-content: flex-end;
  .kbj-a {
    cursor: pointer;
    width: 26px;
    height: 26px;
    margin-top: 2%;
    font-size: 14px;
    color: #333333;
  }
  .kbj-b {
    cursor: pointer;
    margin-top: 2px;
    font-family: PingFang SC;
    font-size: 14px;
    color: #333333;
  }
}
.slot-size {
  width: 88px;
  height: 45px;
  cursor: pointer;
  margin: 0;
  padding: 0;
}
::v-deep.el-input-group__prepend {
  background-color: #f5f7fa;
  color: #909399;
  vertical-align: middle;
  display: table-cell;
  position: relative;
  border: 1px solid #dcdfe6;
  border-radius: 0.20833vw;
  // padding: 0 1.04167vw;
  width: 1px;
  white-space: nowrap;
}

.lban-forms {
  top: 152px;
  right: 246px;
  position: absolute;
  width: 405px;
  // height: 623px;
  height: 580px;
  background-color: #ffffff;
  border-radius: 8px;
}

.RectanguLar {
  width: 1920px;
  height: 885px;

  img {
    width: 1920px;
    height: 885px;
  }
}

// 验证码
.captcha-container {
  display: flex;
  align-items: center;
}

.captcha {
  margin-left: 10px;
  font-size: 16px;
  font-weight: bold;
  border: 1px dashed #333;
  padding: 5px 10px;
}

.check-text {
  font-family: PingFang SC;
  color: #333333;
  font-weight: 500;
  font-size: 16px;
}

div {
  width: 100%;
}

// 悬浮表单
.sform-container {
  position: relative;
  // right: 0;
}

// 行业痛点
.jttd-box {
  width: 1480px;
  margin: 0 auto;
}
.cards-container {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  list-style: none;
  margin-top: 38px;
}

.card {
  background: url("https://kailuo-cms.oss-cn-shenzhen.aliyuncs.com/CMS_base/gwimgs/tdxb-2.png") no-repeat center center;
  background-size: cover;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 250px;
  height: 350px;
  position: relative;
  margin: 10px;
  padding: 20px;
}

.card-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start; /* 内容左侧对齐 */
  padding: 20px;
  text-align: left; /* 确保文本左对齐 */
}

.card-content .number {
  font-family: PingFang SC;
  font-weight: 400;
  font-size: 50px;
  color: #f67100;
  line-height: 15px;
  margin-top: 17px;
  margin-left: 13.5px;
}
.tp1 {
  font-family: PingFang SC;
  font-weight: bold;
  font-size: 23px;
  color: #333333;
  position: relative;
  top: -50px;
  left: 15px;
}
.tp2 {
  font-family: PingFang SC;
  font-weight: bold;
  font-size: 23px;
  color: #333333;
  position: relative;
  top: -40px;
  left: 15px;
}
.tp3 {
  font-family: PingFang SC;
  font-weight: bold;
  font-size: 23px;
  color: #333333;
  position: relative;
  top: -58px;
  left: 15px;
}
.tp4 {
  font-family: PingFang SC;
  font-weight: bold;
  font-size: 23px;
  color: #333333;
  position: relative;
  top: -48px;
  left: 15px;
}
.tp5 {
  font-family: PingFang SC;
  font-weight: bold;
  font-size: 23px;
  color: #333333;
  position: relative;
  top: -48px;
  left: 15px;
}
.card-content p {
  margin: 10px 0;
  color: #333;
}

.card-content .highlight {
  font-family: PingFang SC;
  font-weight: 500;
  font-size: 22px;
  color: #f67100;
  font-style: italic;
  position: relative;
  top: -50px;
  left: 15px;
}
.card-content .highlight-2 {
  font-family: PingFang SC;
  font-weight: 500;
  font-size: 22px;
  color: #f67100;
  font-style: italic;
  position: relative;
  top: -40px;
  left: 10px;
}
.card-content .highlight-3 {
  font-family: PingFang SC;
  font-weight: 500;
  font-size: 22px;
  color: #f67100;
  font-style: italic;
  position: relative;
  top: -50px;
  left: 10px;
}
.card-content .highlight-4 {
  font-family: PingFang SC;
  font-weight: 500;
  font-size: 22px;
  color: #f67100;
  font-style: italic;
  position: relative;
  top: -40px;
  left: 10px;
}
.card-content .highlight-5 {
  font-family: PingFang SC;
  font-weight: 500;
  font-size: 22px;
  color: #f67100;
  font-style: italic;
  position: relative;
  top: -40px;
  left: 10px;
}
</style>
