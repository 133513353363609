<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div class="HeadTop">
      <div class="HeadLeft">
        <img src="../assets/矩形 1@2x.png" />
      </div>
      <div class="HeadRight">
        <ul>
          <li><router-link to="/login">首页</router-link></li>
          <li><router-link to="/place">小程序下单</router-link></li>
          <li><router-link to="/service">合作招商</router-link></li>
          <li class="hover-li">
            <router-link to="/invite">产品&服务 </router-link>
            <!-- <div class="DropDown">
              <p @click="scrollToElement">主营产品</p>
              <p @click="scrollToElementav">配送服务</p>
              <p @click="scrollToElementRc">成功案例</p>
            </div> -->
          </li>

          <li><router-link to="/industry">行业资讯</router-link></li>
          <li><router-link to="/women">关于我们</router-link></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewbenLogin',
  methods: {
    // 主营产品
    scrollToElement () {
      window.scrollTo({
        top: 100,
        behavior: 'smooth'
      })
    },
    // 配送服务
    scrollToElementav () {
      window.scrollTo({
        top: 190,
        behavior: 'smooth'
      })
    },
    // 成功案例
    scrollToElementRc () {
      window.scrollTo({
        top: 350,
        behavior: 'smooth'
      })
    }
  }
  // mounted () {
  //   // 监听滚动事件
  //   window.addEventListener('scroll', this.initScroll)
  // }
  // beforeDestroy () {
  //   // 移除注册事件
  //   window.removeEventListener('scroll', this.initScroll)
  // }
}
</script>

<style lang="less" scoped>
.DropDown {
  width: 110px;
  height: 144px;
  background: #ffffff;
  right: 415px;
  top: 81px;
  position: absolute;
  border-radius: 2px;
  display: none;
  p {
    width: 110px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    background: #ffffff;
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #666666;
    &:hover {
      width: 110px;
      height: 48px;
      background: #ebeef0;
      color: #f67100;
    }
  }
}
.hover-li:hover .DropDown {
  display: block;
}
.HeadTop {
  z-index: 99;
  position: absolute;
  background-color: rgba(0%, 0%, 0%, 0);
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-evenly;
  line-height: 100px;
  .HeadLeft {
    margin: 0 0 0 205px;
    height: 100px;
    line-height: 100px;
    img {
      width: 266px;
      height: 33px;
      top: 10px;
      padding: 0;
    }
  }
  .HeadRight {
    height: 100px;
  }
  .HeadRight ul {
    display: flex;
    height: 80px;
    line-height: 100px;
    li {
      margin: 0 35px 0 0;
      padding-top: 30px;
      font-size: 20px;
      font-weight: 500;
      text-shadow: 0px 2px 3px rgba(116, 116, 116, 0.54);
      color: #ffffff;
      font-family: PingFang SC;
      line-height: 50px;
      &:hover {
        border-bottom: 0.5px solid #ffffff;
      }
    }
  }
  .HeadRight li:hover {
    color: #ffffff;
  }
}
/* 将 ol, ul, li 列表的列表标记设置为 none */
ol,
ul,
li {
  list-style: none;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: inherit; /* 继承父元素的文本颜色 */
  text-decoration: none; /* 移除下划线 */
}
</style>
