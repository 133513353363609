<template>
  <!-- 行业资讯 -->
  <div>
    <!-- 导航栏 -->
    <Medse></Medse>
    <div class="Industry">
      <img src="../../assets/hanyie.jpg" alt="" />
    </div>
    <!-- 中部分类导航 -->
    <div class="baby-Surface">
      <ul class="baby-Surface-ul">
        <li>
          <router-link to="/industry"><i class="iconfont">&#xe620;</i>全部新闻</router-link>
          <span></span>
        </li>

        <li>
          <router-link to="/industry"><i class="iconfont">&#xe620;</i>行业动态</router-link>
          <span></span>
        </li>

        <li>
          <router-link to="/industry"><i class="iconfont">&#xe620;</i>媒体报道</router-link>
          <span></span>
        </li>
      </ul>
    </div>
    <!-- 页面内容 -->
    <div class="page-layout">
      <header class="header-a">
        <span class="zx-title">{{ newsDetail.title }}</span>
        <div class="info">
          <span class="in-1">发布时间：{{ formatDate(newsDetail.update_time) }}</span>
          <span class="in-2">点击数：150</span>
        </div>
        <div class="divider"></div>
      </header>
      <!-- 富文本部分 -->
      <p class="content-box" v-html="unescapedContent"></p>
    </div>

    <!-- 底部切换 -->
    <nav class="navigation">
      <div class="nav-item">
        <img src="../../assets/imgs/zz1.png" alt="Previous" />&nbsp;
        <span>上一篇 不一样的温情</span>
      </div>
      <router-link to="/" tag="div" class="nav-item">
        <img src="../../assets/imgs/hh1.png" alt="login" />&nbsp;
        <span>返回资讯首页</span>
      </router-link>
      <div class="nav-item">
        <span>下一篇 凯螺到家的配套优势</span>&nbsp;
        <img src="../../assets/imgs/yy1.png" alt="Next" />
      </div>
    </nav>
    <!-- <FenY></FenY> -->
    <BottomToncopy class="listBox"></BottomToncopy>
  </div>
</template>

<script>
import BottomToncopy from "@/components/BottomTonCopy.vue";
import Medse from "@/components/Medse.vue";
import { getNewsDetail } from "@/api";
import { imageService } from "@/utils/request";

export default {
  name: "IndustryDetails",
  props: ["id"],
  data() {
    return {
      newsDetail: {},
      unescapedContent: "",
    };
  },
  async mounted() {
    await this.fetchNewsDetail();
  },
  methods: {
    async fetchNewsDetail() {
      if (!this.id) {
        console.error("缺少新闻ID");
        return;
      }
      try {
        const response = await getNewsDetail({ id: this.id });
        this.newsDetail = response.data[0];
        // 解码转义的HTML实体
        let decodedHtml = this.decodeHtml(this.newsDetail.content);

        // 处理富文本内容中的图片URL
        this.unescapedContent = this.processImageUrls(decodedHtml);
      } catch (error) {
        // console.error("获取-新闻详情失败:", error); // 在控制台打印错误信息
      }
    },
    decodeHtml(encodedHtml) {
      const textarea = document.createElement("textarea");
      textarea.innerHTML = encodedHtml;
      return textarea.value;
    },
    getImageUrl(path) {
      return `${imageService.defaults.baseURL}${path}`;
    },
    processImageUrls(htmlContent) {
      return htmlContent.replace(
        /<img [^>]*src=['"]([^'"]+)['"][^>]*>/gi,
        (match, p1) => {
          return `<img src='${p1.indexOf("http") > -1 ? p1 : this.getImageUrl(p1)
            }' />`;
        }
      );
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
  },
  components: {
    BottomToncopy,
    Medse,
  },
};
</script>

<style scoped lang="less">
.listBox {
  width: 1920px;
  background-color: #f0f0f0;
  border-top: 4px solid #f67100;
  position: relative;
}

.baby-Surface {
  margin-bottom: 50px;
  width: 1920px;
  height: 90px;
  line-height: 90px;
  background-color: #fff;

  .baby-Surface-ul {
    width: 525px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    color: #e6e6e6;

    li {
      width: 175px;
      height: 90px;
      position: relative;
      line-height: 90px;
      text-align: center;

      a {
        font-family: PingFang SC;
        display: block;
        font-weight: 500;
        height: 40px;
        font-size: 16px;
        // line-height: 40px;
        text-align: center;
        color: #666666;

        .iconfont {
          vertical-align: middle;
          margin-right: 9px;
          width: 32px;
          height: 32px;
          color: #666666;
        }
      }

      span {
        width: 0.5px;
        height: 24px;
        right: 0;
        top: 32px;
        position: absolute;
        background: #e6e6e6;
      }
    }

    li:hover {
      border-bottom: 4px solid #f67100;
    }

    li:hover a,
    .iconfont {
      color: #f67100;
    }

    li:hover .iconfont {
      color: #f67100;
    }
  }
}



.Industry {
  width: 1920px;
  height: 380px;
  background-color: #fff;

  img {
    width: 1920px;
    height: 380px;
  }
}

div {
  background-color: #f0f0f0;
}

ol,
ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

// 新闻详情
.page-layout {
  font-family: Arial, sans-serif;
  // padding: 20px;
  width: 1320px;
  // height: 1366px;
  margin: 0 auto;
  padding: 0 50px;
  padding-top: 1px;
  background-color: #ffffff;
  min-height: 1200px;
  // background-color: #94f42e;
}

.header-a {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;

  .zx-title {
    // text-align: center;
    width: 553px;
    // height: 34px;
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 36px;
    color: #333333;
    margin-top: 80px;
    display: block;
  }
}

.info {
  width: 1320px;
  margin-top: 30px;
  font-size: 14px;
  // color: #666;
  background-color: #fff;

  .in-1 {
    width: 193px;
    height: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #666666;
    line-height: 28px;
    // margin-right: 20px;
  }

  .in-2 {
    width: 80px;
    height: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #666666;
    line-height: 28px;
    // margin-right: 20px;
    padding-right: 50px;
    margin-left: 20px;
  }
}

.content-box {
  margin-top: 10px;
  margin-left: 99px;
  margin-right: 100px;
  padding-bottom: 40px;
  margin-bottom: 40px;

  .content-box p {
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #666666;
    line-height: 28px;
  }

  .content-box img {
    margin-left: 99px;
    margin-right: 100px;
    max-width: 100%;
    height: auto;
    display: block;
    // margin: 0 auto;
    margin-bottom: 41px;
    margin-top: 41px;
  }
}

.divider {
  width: 100%;
  border: none;
  border-top: 1px solid #f1f1f1;
  margin: 10px 0;
}

// 底部切换
.navigation {
  display: flex;
  width: 1320px;
  height: 64px;
  background: #ffffff;
  margin-left: 300px;
  margin-top: 10px;
  margin-bottom: 66px;
}

.nav-item {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  background: #ffffff;
  width: 136px;
  // height: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  font-size: 14px;
  color: #666666;
  line-height: 28px;
  cursor: pointer;
}

.nav-item img {
  margin: 0 5px;
}

.nav-item span {
  white-space: nowrap;
}

</style>
