<template>
  <div>
    <div class="btn-sbmt" ref="Refsbmt">
      <div class="btn-sbmt-ziT">
        <slot>配送服务</slot>
      </div>
      <div class="btn-sbmt-p"></div>
    </div>
    <div class="Lowni">
      <img class="LowImg" src="https://kailuo-cms.oss-cn-shenzhen.aliyuncs.com/CMS_base/gwimgs/huowu.webp" alt="1" />
    </div>
    <div class="boc-ckcakal">
      <div class="left-img">
        <img width="100%" height="100%" src="https://kailuo-cms.oss-cn-shenzhen.aliyuncs.com/CMS_base/gwimgs/lai01.webp" />
      </div>
      <div class="right-img">
        <ul>
          <li><img src="https://kailuo-cms.oss-cn-shenzhen.aliyuncs.com/CMS_base/gwimgs/lai02.webp" alt="" /></li>
          <li><img src="https://kailuo-cms.oss-cn-shenzhen.aliyuncs.com/CMS_base/gwimgs/lai03.webp" alt="" /></li>
          <li><img src="https://kailuo-cms.oss-cn-shenzhen.aliyuncs.com/CMS_base/gwimgs/lai04.webp" alt="" /></li>
          <li><img src="https://kailuo-cms.oss-cn-shenzhen.aliyuncs.com/CMS_base/gwimgs/lai05.webp" alt="" /></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NodFneName',
  mounted () {
    // const Refsbmt = this.$refs.Refsbmt
  }
}
</script>

<style scopes lang="less">
.boc-ckcakal {
  display: flex;
  margin: 61px 159px;
  width: 1604px;
  height: 462px;
  .left-img {
    width: 726px;
    height: 462px;
    margin-right: 30px;
  }
  .right-img {
    flex: 1;
    ul {
      display: flex;
      width: 100%;
      height: 462px;
      flex-wrap: wrap;
      align-content: space-between;
      justify-content: space-between;
      li {
        display: block;
        width: 411px;
        height: 216px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
.btn-sbmt {
  width: 1920px;
  height: 160px;
  .btn-sbmt-ziT {
    width: 460px;
    height: 157px;
    text-align: center;
    margin: 0 auto;
    line-height: 157px;
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 60px;
    color: #333333;
    // line-height: 30px;
  }
  .btn-sbmt-p {
    width: 110px;
    height: 3px;
    background: #f67100;
    text-align: center;
    margin: 0 auto;
  }
}
.Lowni {
  margin: 60px auto 80px;
  // margin: 0 auto;
  margin-top: 58px;
  text-align: center;
  width: 1920px;
  height: 249px;
  img {
    width: 1603px;
    height: 249px;
    // border-style: dashed;
  }
}
</style>
