<template>
  <div>
    <div class="box-bottom-ton">
      <div class="box-bottom-left">
        <ul>
          <li class="gonshi">广州凯螺科技发展有限公司</li>
          <li>地址：广州荔湾区凯螺到家一号云仓月台上新办公室</li>
          <li>凯螺官网移动版</li>
          <li>Copyright © 2024 KaiLUO.版权所有.粤ICP备20000176号-2</li>
        </ul>
      </div>
      <div class="box-bottom-right">
        <ul class="box-bottom-rightUl">
          <li><router-link to="/login">首页</router-link></li>
          <li><router-link to="/place">小程序下单</router-link></li>
          <li><router-link to="/service">合作招商</router-link></li>
          <li>
            <router-link to="/invite">产品&服务 </router-link>
          </li>
          <li><router-link to="/industry">行业资讯</router-link></li>
          <li><router-link to="/women">关于我们</router-link></li>
        </ul>
        <!-- <div class="LiFlex">
          <ul class="LiFlex-ul">
            <li><a href="#">主营产品</a></li>
            <li><a href="#">配送服务</a></li>
            <li><a href="#">成功案例</a></li>
          </ul>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.router-link-active {
  color: #f67100;
}
.box-bottom-ton {
  display: flex;
  padding-top: 60px;
  width: 1250px;
  height: 272px;
  margin-top: 60px;
  margin: 0 auto;
  .box-bottom-left {
    width: 400px;
    ul {
      height: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 14px;
      color: #3a3a3a;
      li {
        padding-bottom: 13px;
        img {
          width: 362px;
          height: 15px;
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 14px;
          color: #3a3a3a;
          line-height: 30px;
        }
      }
    }
  }
  .box-bottom-right .box-bottom-rightUl li:hover a {
    color: #f67100;
  }
  .box-bottom-right {
    position: relative;
    padding-left: 9.125vw;
    flex: 1;
    ul {
      display: flex;
      justify-content: space-evenly;
      li {
        // width: 94px;
        height: 19px;
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 20px;
        color: #333333;
        line-height: 30px;
      }
    }
  }
  .box-bottom-right .LiFlex {
    position: absolute;
    width: 100px;
    height: 100px;
    right: 245px;
    line-height: 100px;
    margin-top: 20px;
    .LiFlex-ul {
      display: inline-block;
      li {
        margin-bottom: 15px;
      }
    }
  }
}
* {
  border: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: inherit; /* 继承父元素的文本颜色 */
  text-decoration: none; /* 移除下划线 */
}
/* 将 ol, ul, li 列表的列表标记设置为 none */
ol,
ul,
li {
  list-style: none;
}
.gonshi {
  font-family: PingFang SC;
  font-weight: 600;
  font-size: 16px;
  color: #3a3a3a;
  line-height: 15px;
}
</style>
