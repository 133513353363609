<template>
  <!-- 产品详情 -->
  <div class="product-box;">
    <div class="HeadTop">
      <div class="HeadLeft">
        <img src="../../assets/logo2.png" />
      </div>
      <div class="HeadRight">
        <ul>
          <li><router-link to="/login">首页</router-link></li>
          <li><router-link to="/place">小程序下单</router-link></li>
          <li><router-link to="/service">合作招商</router-link></li>
          <li><router-link to="/invite">产品&服务</router-link></li>
          <li><router-link to="/industry">行业资讯</router-link></li>
          <li><router-link to="/women">关于我们</router-link></li>
        </ul>
      </div>
    </div>
    <div style="height: 100px"></div>
    <!-- 产品详情 -->
    <div class="container-1">
      <div class="image-box">
        <img style="width: 100%" :src="getImageUrl(product?.ico)" alt="产品详情" />
      </div>
      <div class="details">
        <p class="xp-1 box">{{ product?.title }}</p>
        <span class="xp-2 box">产品描述 </span>
        <span class="underline"></span>
        <p class="xp-3 box">{{ product?.description }}</p>
        <span class="box-2"><img class="img" src="../../assets/二维码.png" alt="二维码" /></span>
        <div class="buttons">
          <!-- <el-button class="left-button">上一款产品</el-button>
          <el-button class="right-button" type="primary">下一款产品</el-button> -->
        </div>
      </div>
    </div>
    <!-- 富文本展示区域 -->
    <div class="container-2">
      <div class="fwb-box" v-html="unescapedContent"></div>
    </div>
    <BottomTonCopy class="listBox"></BottomTonCopy>
  </div>
</template>

<script>
import BottomTonCopy from "@/components/BottomTonCopy.vue";
import { getProductDetail } from "@/api";
import { imageService } from "@/utils/request";

export default {
  name: "ProductDetails",
  props: ["id"],
  components: {
    BottomTonCopy,
  },
  data() {
    return {
      product: null,
      unescapedContent: "",
    };
  },
  async mounted() {
    if (this.id) {
      await this.fetchProductDetails(this.id);
    } else {
      console.error("缺少产品ID");
    }
  },
  methods: {
    async fetchProductDetails(id) {
      try {
        const response = await getProductDetail({ id });
        this.product = response.data[0];
        // console.log("获取的产品详情:", this.product);
        let decodedHtml = this.decodeHtml(this.product.content);
        this.unescapedContent = this.processImageUrls(decodedHtml);
      } catch (error) {
        // console.error("获取产品详情失败:", error);
      }
    },
    decodeHtml(encodedHtml) {
      const textarea = document.createElement("textarea");
      textarea.innerHTML = encodedHtml;
      return textarea.value;
    },
    isCompleteUrl(url) {
      return (
        typeof url === "string" &&
        (url.startsWith("http://") || url.startsWith("https://"))
      );
    },
    getImageUrl(path) {
      return this.isCompleteUrl(path)
        ? path
        : `${imageService.defaults.baseURL}${path}`;
    },
    processImageUrls(htmlContent) {
      return htmlContent.replace(
        /<img [^>]*src=['"]([^'"]+)['"][^>]*>/gi,
        (match, p1) => {
          return `<img src='${p1.indexOf("http") > -1 ? p1 : this.getImageUrl(p1)
            }' />`;
        }
      );
    },
  },
};
</script>

<style scoped lang="less">
//导航
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: inherit;
  /* 继承父元素的文本颜色 */
  text-decoration: none;
  /* 移除下划线 */
}

img {
  width: 100%;
}

.HeadTop {
  z-index: 99;
  position: absolute;
  background-color: rgba(0%, 0%, 0%, 0);
  // background-color: #c4f025;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-evenly;
  line-height: 100px;

  .HeadLeft {
    margin: 0 0 0 205px;
    height: 100px;
    line-height: 100px;

    img {
      width: 266px;
      height: 33px;
      top: 10px;
      padding: 0;
    }
  }

  .HeadRight {
    height: 100px;
  }

  .HeadRight ul {
    display: flex;
    height: 80px;
    line-height: 100px;

    li {
      // height: 100px;
      margin: 0 35px 0 0;
      padding-top: 30px;
      font-size: 20px;
      font-weight: 500;
      font-family: PingFang SC;
      line-height: 50px;

      font-family: PingFang SC;
      font-weight: 500;
      font-size: 20px;
      color: #333333;

      &:hover {
        border-bottom: 0.5px solid #f67100;
      }
    }
  }

  .HeadRight li:hover {
    color: #f67100;
  }
}

/* 将 ol, ul, li 列表的列表标记设置为 none */
ol,
ul,
li {
  list-style: none;
}

.listBox {
  width: 1920px;
  background-color: #f0f0f0;
  border-top: 4px solid #f67100;
  position: relative;
}

.about-bog {
  width: 1920px;
  height: 677px;
  margin-bottom: 20px;

  img {
    width: 1920px;
    height: 677px;
    // border-style: dashed;
  }
}

.product-box {
  background: #f0f0f0 !important;
}

.container-1 {
  width: 1400px;
  height: 592px;
  margin: 0 auto;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  // max-width: 800px;

  // 线
  .underline {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .underline::before {
    content: "";
    flex: 1;
    border-bottom: 2px solid #fa7d3c;
    margin-right: 5px;
  }

  .underline::after {
    content: "";
    flex: 4;
    border-bottom: 1px solid #ddd;
  }

  .image-box {
    width: 654px;
    color: white;
    // padding: 20px;
    margin-left: 100px;
    margin-top: 80px;
    margin-bottom: 80px;

    img {
      max-width: 654px;
      max-height: 432px;
    }
  }

  .details {
    width: 500px;
    padding-left: 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding-right: 20px;
    margin-right: 100px;
    margin-top: 80px;
    margin-bottom: 40px;

    .box {
      flex: 1;
      /* 每个box占据相同的高度 */
      margin-top: 10px;
      background-color: #ffff;
      display: flex;
    }

    .box-2 {
      flex: 1;
      margin-top: 10px;
      background-color: #ffff;
      display: flex;
      margin-left: auto;

      .img {
        width: 100px;
      }
    }

    .xp-1 {
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 26px;
      color: #000000;
      line-height: 30px;
    }

    .xp-2 {
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #000000;
      line-height: 30px;
    }

    .xp-3 {
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 14px;
      color: #666666;
      line-height: 28px;
    }

    .buttons {
      display: flex;
      width: 446px;
      margin-top: 50px;
    }

    .buttons .el-button {
      border-radius: 0;
      margin: 0;
    }

    .buttons .left-button {
      width: 223px;
      height: 50px;
      border-radius: 4px 0px 0px 4px;
      border: 1px solid #f67100;
    }

    .buttons .right-button {
      border: none !important;
      /* 移除边框 */
      box-shadow: none;
      /* 移除阴影 */
      width: 223px;
      height: 50px;
      background: #f67100;
      border-radius: 0px 4px 4px 0px;
    }
  }
}

.container-2 {
  display: flex;
  width: 1400px;
  margin: 0 auto;
  margin-bottom: 20px;
  margin-top: 20px;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  min-height: 1200px;

  .fwb-box {
    width: 790px;
    margin-top: 90px;
    margin-left: 300px;
    margin-right: 300px;
    margin-bottom: 70px;
  }
}
</style>
