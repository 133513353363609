<template>
  <!-- 关于我们 -->
  <div class="yuou">
    <!-- 导航栏 -->
    <Medse></Medse>
    <div class="about-bog">
      <img
        src="https://kailuo-cms.oss-cn-shenzhen.aliyuncs.com/CMS_base/gwimgs/关于我们1.webp"
        alt=""
      />
    </div>
    <div class="btn-sbmt">
      <div class="btn-sbmt-ziT">
        <slot>关于凯螺</slot>
      </div>
      <div class="btn-sbmt-p"></div>
      <p>
        凯螺到家，2019年12月诞生于中国广州，是国内知名互联网基建平台，以“
        好建材、选凯螺
        ”为企业使命。凯螺到家基础建材网，由广州市凯螺科技发展有限公司负责整体运营。中国基础建材行业具有价值链长、市场参与者类别众多及服务非标准化的特点，凯螺到家作为互联网基建平台，致力于行业的数字化，并赋能市场参与者，不断提升中国建材行业的效率及透明度。
        凯螺到家：主要经营为“互联网+基建”的商业模式。在线上平台业务中，向用户提供一站式基础建材解决方案，包括信息发现及与区域服务供应商智能匹配、实现客户在线上下单，线下就会有相应的配送与搬运体系的配合，以及客户意见反馈和其他增值服务；同时，凯螺到家向平台服务每位供应商提供订单推荐、会员服务、推介服务及保险推广及供应链管理解决方案。在基础建材业务中，为客户提供施工基础建材、设备租赁买卖、打拆清运
        等等服务及基础主要高性价比的材料。 凯螺到家致力于成
        为每个施工方与B端客户信赖的线上基础建材的平台，以及鼓励服务供应商围绕的低成本、高效率、一站式的服务平台。截至2019年12月31日，凯螺到家平台上有约基础建材经销商家400名、20个主体厂家，运力体系约200辆车辆、搬运体系约500名工人，目前业务覆盖国内中国广州荔湾区和越秀区，累计服务约2000个用户。
        品牌名称“凯螺到家”指的是“中国基建施工行业的一群快乐的小蚂蚁，为每一位用户提供一站式的服务体验、成为您身边最具信赖的服务平台
        ”，根据艾瑞咨询的资料，凯螺到家有潜力将会成为中国互联网基建服务知名品牌。
      </p>
    </div>
    <div class="Kayi-img">
      <div class="img-box">
        <div class="btn-sbmt-ziT-1">
          <slot>合作品牌</slot>
        </div>
        <div class="btn-sbmt-p-1"></div>
        <!-- 图标 -->
        <div class="tb-box-1">
          <div class="logo-container">
            <img
              src="https://kailuo-cms.oss-cn-shenzhen.aliyuncs.com/CMS_base/gwimgs/pp%E5%BE%B7%E9%AB%98%402x.png"
              title="德高"
            />
            <img
              src="https://kailuo-cms.oss-cn-shenzhen.aliyuncs.com/CMS_base/gwimgs/%E4%B8%9C%E6%96%B9%E9%9B%A8%E8%99%B9%402x.png"
              alt="Logo 2"
              title="东方雨虹"
            />
            <img
              src="https://kailuo-cms.oss-cn-shenzhen.aliyuncs.com/CMS_base/gwimgs/%E5%A4%9A%E9%82%A6%402x.png"
              title="多邦"
            />
            <img
              src="https://kailuo-cms.oss-cn-shenzhen.aliyuncs.com/CMS_base/gwimgs/%E6%B6%A6%E5%93%81%402x.png"
              title="润品"
            />
            <img
              src="https://kailuo-cms.oss-cn-shenzhen.aliyuncs.com/CMS_base/gwimgs/%E7%9F%B3%E4%BA%95%402x.png"
              title="石井"
            />
            <img
              src="https://kailuo-cms.oss-cn-shenzhen.aliyuncs.com/CMS_base/gwimgs/%E7%AB%8B%E9%82%A6%402x.png"
              title="立邦"
            />
          </div>
        </div>
        <div class="tb-box-2">
          <div class="logo-container">
            <img src="https://kailuo-cms.oss-cn-shenzhen.aliyuncs.com/CMS_base/gwimgs/%E8%81%94%E5%A1%91%402x.png"  title="联塑"/>
            <img src="https://kailuo-cms.oss-cn-shenzhen.aliyuncs.com/CMS_base/gwimgs/%E6%B5%B7%E8%9E%BA%E6%B0%B4%E6%B3%A5%402x.png"  title="海螺水泥"/>
            <img src="https://kailuo-cms.oss-cn-shenzhen.aliyuncs.com/CMS_base/gwimgs/%E9%87%91%E5%A4%9A%E5%AE%9D%402x.png"  title="金多宝"/>
            <img src="https://kailuo-cms.oss-cn-shenzhen.aliyuncs.com/CMS_base/gwimgs/%E9%87%91%E7%BE%8A%E7%89%8C%402x.png" title="金羊牌"/>
            <img src="https://kailuo-cms.oss-cn-shenzhen.aliyuncs.com/CMS_base/gwimgs/%E5%A4%A9%E5%A5%B3%402x.png" title="天女"/>
            <img src="https://kailuo-cms.oss-cn-shenzhen.aliyuncs.com/CMS_base/gwimgs/%E8%8B%B1%E7%9B%9B%402x.png" title="英盛"/>
          </div>
        </div>
      </div>
      <!-- <img
        src="https://kailuo-cms.oss-cn-shenzhen.aliyuncs.com/CMS_base/gwimgs/关于我们2.webp"
        alt="12"
      /> -->
    </div>
    <BottomTonCopy class="listBox"></BottomTonCopy>
  </div>
</template>

<script>
import BottomTonCopy from "@/components/BottomTonCopy.vue";
import Medse from "@/components/Medse.vue";

export default {
  name: "womenName",
  components: {
    BottomTonCopy,
    Medse,
  },
};
</script>

<style scoped lang="less">
.listBox {
  width: 1920px;
  background-color: #f0f0f0;
  border-top: 4px solid #f67100;
  position: relative;
}

.Kayi-img {
  width: 1604px;
  // height: 549px;
  margin: 0 auto;
  margin-bottom: 20px;

  .img-box {
    width: 100%;
    // height: 594px;
    height: 550px;
    // background-color: #a5dd22;
    background-color: #ffff;
  }
  img {
    width: 1604px;
    height: 549px;
  }
}

.btn-sbmt {
  width: 1604px;
  height: 645px;
  background: #ffffff;
  margin: 0 auto;
  margin-bottom: 20px;

  .btn-sbmt-ziT {
    width: 460px;
    height: 157px;
    text-align: center;
    margin: 0 auto;
    line-height: 157px;
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 60px;
    color: #333333;
    // line-height: 30px;
  }

  .btn-sbmt-p {
    width: 110px;
    height: 3px;
    background: #f67100;
    text-align: center;
    margin: 0 auto;
  }

  p {
    padding: 60px 100px 80px 100px;
    margin: 0 auto;
    width: 1401px;
    height: 346px;
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #666666;
    line-height: 30px;
  }
}

.about-bog {
  width: 1920px;
  height: 677px;
  margin-bottom: 20px;

  img {
    width: 1920px;
    height: 677px;
    // border-style: dashed;
  }
}

.yuou {
  background: #f0f0f0;
}
// 合作品牌
.btn-sbmt-ziT-1 {
  width: 460px;
  height: 137px;
  text-align: center;
  margin: 0 auto;
  line-height: 137px;
  font-family: PingFang SC;
  font-weight: 500;
  font-size: 60px;
  color: #333333;
  // line-height: 30px;
}

.btn-sbmt-p-1 {
  width: 110px;
  height: 3px;
  background: #f67100;
  text-align: center;
  margin: 0 auto;
}
// 图标
.tb-box-1 {
  width: 1420px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: #f8f8f8;
}
.tb-box-2 {
  width: 1420px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -57px;
}
.logo-container {
  // position: absolute;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 10px;
  margin-top: 65px;
  // padding: 20px;
  // background-color: white;
  // border: 1px dashed #000;
}

.logo-container img {
  width: 355px;
  max-width: 100%;
  height: auto;
  display: block;
}

// .brand-list {
//   list-style: none;
//   padding: 20px;
//   margin: 0 auto;
//   max-width: 1460px;
//   overflow: hidden;
// }

// .brand-item {
//   float: left;
//   width: 20%;
//   // width: 260.5px;
//   // padding: 10px;
//   box-sizing: border-box;
//   position: relative;
// }

// .brand-icon {
//   position: relative;
//   width: 50%;
//   padding-top: 75%; /* 4:3 Aspect Ratio */
//   // background-color: #fff;
//   // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
// }

// .base-image {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
// }

// .logo-image {
//   position: absolute;
//   top: 45px;
//   left: 99px;
//   transform: translate(-50%, -50%);
//   max-width: 80%;
//   max-height: 80%;
// }
</style>
