<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div class="HeadTop">
      <div class="HeadLeft">
        <!-- <img src="../assets/矩形 1@2x.png" /> -->
        <img src="../assets/imgs/矩形 1.png" />
      </div>
      <div class="HeadRight">
        <ul>
          <li><router-link to="/login">首页</router-link></li>
          <li><router-link to="/place">小程序下单</router-link></li>
          <li><router-link to="/service">合作招商</router-link></li>
          <li><router-link to="/invite">产品&服务</router-link></li>
          <li><router-link to="/industry">行业资讯</router-link></li>
          <li><router-link to="/women">关于我们</router-link></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewbenLogin'
}
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: inherit; /* 继承父元素的文本颜色 */
  text-decoration: none; /* 移除下划线 */
}
.HeadTop {
  z-index: 99;
  position: absolute;
  background-color: rgba(0%, 0%, 0%, 0);
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-evenly;
  line-height: 100px;
  .HeadLeft {
    margin: 0 0 0 205px;
    height: 100px;
    line-height: 100px;
    img {
      // width: 266px;
      // height: 33px;
      top: 10px;
      padding: 0;
    }
  }
  .HeadRight {
    height: 100px;
  }
  .HeadRight ul {
    display: flex;
    height: 80px;
    line-height: 100px;
    li {
      // height: 100px;
      margin: 0 35px 0 0;
      padding-top: 30px;
      font-size: 20px;
      font-weight: 500;
      text-shadow: 0px 2px 3px rgba(116, 116, 116, 0.54);
      color: #ffffff;
      font-family: PingFang SC;
      line-height: 50px;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
      &:hover {
        border-bottom: 0.5px solid #ffffff;
      }
    }
  }
  .HeadRight li:hover {
    color: #ffffff;
  }
}
/* 将 ol, ul, li 列表的列表标记设置为 none */
ol,
ul,
li {
  list-style: none;
}
</style>
