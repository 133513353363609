import axios from 'axios';

// 创建用于 API 请求的 axios 实例
const apiService = axios.create({
  baseURL: 'http://wcmsapi.kailluo.com', // 接口的基础路径
  timeout: 5000, // 请求超时时间
});

// 创建用于图片资源请求的 axios 实例
const imageService = axios.create({
  baseURL: 'http://wcmsadmin.kailluo.com', // 图片资源的基础路径
  timeout: 5000, // 请求超时时间
});

// API 请求拦截器
apiService.interceptors.request.use(
  config => {
    // 可以在这里添加 token
    return config;
  },
  error => {
    // 处理请求错误
    return Promise.reject(error);
  }
);

// API 响应拦截器
apiService.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    // 处理响应错误
    return Promise.reject(error);
  }
);

// 图片资源请求拦截器
imageService.interceptors.request.use(
  config => {
    // 可以在这里添加 token
    return config;
  },
  error => {
    // 处理请求错误
    return Promise.reject(error);
  }
);

// 图片资源响应拦截器
imageService.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    // 处理响应错误
    return Promise.reject(error);
  }
);

export { apiService, imageService };
